import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { toast } from "react-toastify";
import { post, authformpost, authget } from "../../utils/service";
import "react-toastify/dist/ReactToastify.css";
import { Link, withRouter } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import * as CONSTANT from "../../utils/constant";
toast.configure();

function View_user(props) {
  const [users, setUsers] = useState([]);
  const [open, setopen] = useState(false);
  const [userdetails, setUserdetails] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    authget(`/api/showClient`)
      .then((response) => {
        // console.log(response)
        let consaltent_id = localStorage.getItem("consaltent_id");
        const u_l = response.data.data.get_user.filter(
          (gs) => gs.consaltent_id == consaltent_id
        );
        setUsers(u_l);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  const statusChange = (id) => {
    authget(`/api/statuschangeu?id=${id}`)
      .then((response) => {
        console.log(response.data.data);
        if (response.data.status === true) {
          setUsers(response.data.data);
          toast.dark(response.data.msg, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
        } else {
          toast.dark(response.data.msg, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const libraryChange = (id) => {
    authget(`/api/lschange?id=${id}`)
      .then((response) => {
        console.log(response.data.data);
        if (response.data.status === true) {
          setUsers(response.data.data);
          toast.dark(response.data.msg, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
        } else {
          toast.dark(response.data.msg, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const onCloseModal = () => {
    setopen(false);
  };
  const Viewuser = (data) => {
    setopen(true);
    setUserdetails(data);
  };

  const abc = (id) => {
    props.history.push("/payment_transaction?id=" + id);
  };

  return (
    <div>
      <Modal open={open} onClose={onCloseModal}>
        <h2>User Details</h2>
        <div className="row">
          <div className="col-12" style={{ width: "750px" }}>
            <div className="card shadow">
              <div className="card-header bg-transparent border-0">
                <div className="gallery row">
                  {userdetails !== null ? (
                    <>
                      City: {userdetails.city}
                      <br />
                      Pin Code: {userdetails.pin_code}
                      <br />
                      State: {userdetails.state}
                      <br />
                      Extra Phone number: {userdetails.extra_phone_no}
                      <br />
                      <div>
                        {" "}
                        Profile Image:{" "}
                        <a
                          href={`${CONSTANT.URL}/storage/${userdetails.profile_image}`}
                          className="big"
                        >
                          <img
                            src={`${CONSTANT.URL}/storage/${userdetails.profile_image}`}
                            alt=""
                            title="Beautiful Image"
                            style={{ width: "50px" }}
                          />
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      <p>Details Not Fillup By User</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="content">
        <div className="container-fluid">
          <div className="row page-title">
            <div className="col-md-12">
              <nav aria-label="breadcrumb" className="float-right mt-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Users
                  </li>
                </ol>
              </nav>
              <h4 className="mb-1 mt-0"> User</h4>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {/* <h4 className="header-title mt-0 mb-1" style={{"float":"right"}}><Link to="add_user" className="btn btn-success btn-sm" >Add</Link></h4><br></br><br></br> */}
                  <MaterialTable
                    title="User list"
                    columns={[
                      { title: "Name", field: "name" },
                      { title: "Email", field: "email" },
                      { title: "Contact No", field: "phone", type: "numeric" },
                      { title: "Address", field: "address" },
                      { title: "Due Payment", field: "user_balance" },

                      {
                        title: "Library Status",
                        field: "status",
                        render: (row) => (
                          <>
                            {" "}
                            {row.library_status == 1 ? (
                              <>
                                <center>
                                  <span className="badge badge-pill badge-success">
                                    Activate
                                  </span>
                                </center>
                              </>
                            ) : (
                              <>
                                {" "}
                                <center>
                                  <span className="badge badge-pill badge-danger">
                                    Deactivate
                                  </span>
                                  <br />
                                  <button
                                    className="btn btn-success btn-sm btn-pill"
                                    onClick={() => libraryChange(row.user_id)}
                                  >
                                    Active
                                  </button>
                                </center>
                              </>
                            )}
                          </>
                        ),
                      },
                      {
                        title: "Status",
                        field: "status",
                        render: (row) => (
                          <div className={row.status ? "active1" : "deactive1"}>
                            {row.status === 1 ? "Active" : "Deactive"}
                          </div>
                        ),
                      },
                      {
                        title: "Status",
                        field: "status",
                        render: (row) => (
                          <div>
                            <button
                              className="btn btn-success btn-sm"
                              onClick={() => abc(row.id)}
                            >
                              {" "}
                              View Payment
                            </button>
                          </div>
                        ),
                      },
                    ]}
                    data={users}
                    options={{
                      actionsColumnIndex: -1,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(View_user);
