import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { post, authformpost, authget } from "../../utils/service";

export default function Offer() {
  const [offer, setOffer] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    abc();
  }, []);

  const abc = () => {
    authget(`/api/view_offer`)
      .then((response) => {
        console.log(response.data);
        setOffer(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="content">
        <div className="container-fluid">
          <div className="row page-title">
            <div className="col-md-12">
              <nav aria-label="breadcrumb" className="float-right mt-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Offer
                  </li>
                </ol>
              </nav>
              <h4 className="mb-1 mt-0">Offer</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  {/* <a href="" className="btn btn-primary btn-sm float-right"> <i className="uil uil-export ml-1"></i> Export </a> */}
                  <h5 className="card-title mt-0 mb-0 header-title">Offer</h5>
                  <div className="table-responsive mt-4">
                    <table className="table table-hover table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Offer</th>
                          <th scope="col">Validity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {offer &&
                          offer
                            .filter((gs) => gs.type === "consultant")
                            .map((object, i) => (
                              <tr>
                                <td>{object.offer}</td>
                                <td>{object.valid_date}</td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
