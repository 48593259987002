
import View_user from '../components/user/View_user'

import {connect} from 'react-redux'


const mapStateToProps=state=>({
    
})
const mapDispatchToProps=dispatch=>({
    

})
export default connect(mapStateToProps,mapDispatchToProps)(View_user)