import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { toast } from "react-toastify";
import { post, authformpost, authget, authpost } from "../../utils/service";
import "react-toastify/dist/ReactToastify.css";
import { Link, withRouter } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import * as CONSTANT from "../../utils/constant";
toast.configure();

function ViewPlumber(props) {
  const [plumber, setPlumbers] = useState([]);
  const [open, setopen] = useState(false);
  const [PlumberDetails, setPlumberDetails] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    ah();
  }, []);

  const ah = () => {
    authget(`/api/viewWorkers`)
      .then((response) => {
        setPlumbers(response.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const statuseChange = (id) => {
    let data = { worker_id: id };
    authpost("/api/workerstatuschange", data)
      .then((response) => {
        ah();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const abc = (id) => {
    props.history.push("/view-plumber-rating?id=" + id);
  };

  const abce = (id) => {
    props.history.push("/edit-plumber?id=" + id);
  };

  return (
    <div>
      <div className="content">
        <div className="container-fluid">
          <div className="row page-title">
            <div className="col-md-12">
              <nav aria-label="breadcrumb" className="float-right mt-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Service Providers
                  </li>
                </ol>
              </nav>
              <h4 className="mb-1 mt-0">Add Service Providers</h4>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4
                    className="header-title mt-0 mb-1"
                    style={{ float: "right" }}
                  >
                    <Link to="add-plumber" className="btn btn-success btn-sm">
                      Add
                    </Link>
                  </h4>
                  <br></br>
                  <br></br>
                  <MaterialTable
                    title="Service Providers list"
                    columns={[
                      { title: "Name", field: "w_name" },
                      { title: "Occupation", field: "s_category_name" },
                      // { title: "Address", field: "w_address" },
                      { title: "city", field: "city_name" },
                      // { title: "Occupation", field: "w_occupatation" },
                      { title: "Phone no", field: "w_phone_no" },
                      { title: "Whatapp no", field: "w_whatapp_no" },
                      // { title: "Email id", field: "w_email_id" },
                      { title: "Remarks", field: "w_remerks" },
                      { title: "Total Review", field: "w_total_review" },
                      { title: "Rating", field: "w_reating" },

                      {
                        title: "Status",
                        field: "status",
                        render: (row) => (
                          <>
                            {" "}
                            {row.w_status == "YES" ? (
                              <>
                                <center>
                                  <span className="badge badge-pill badge-success">
                                    Activate
                                  </span>
                                  <br />
                                  <button
                                    className="btn btn-danger btn-sm btn-pill"
                                    onClick={() => statuseChange(row.id)}
                                  >
                                    Inactive
                                  </button>
                                </center>
                              </>
                            ) : (
                              <>
                                {" "}
                                <center>
                                  <span className="badge badge-pill badge-danger">
                                    Inactive
                                  </span>
                                  <br />
                                  <button
                                    className="btn btn-success btn-sm btn-pill"
                                    onClick={() => statuseChange(row.id)}
                                  >
                                    Active
                                  </button>
                                </center>
                              </>
                            )}
                          </>
                        ),
                      },
                      // {
                      //   title: "Status",
                      //   field: "status",
                      //   render: (row) => (
                      //     <div className={row.status ? "active1" : "deactive1"}>
                      //       {row.status === 1 ? "Active" : "Deactive"}
                      //     </div>
                      //   ),
                      // },
                      {
                        title: "View",
                        field: "status",
                        render: (row) => (
                          <div>
                            <button
                              className="btn btn-success btn-sm"
                              onClick={() => abc(row.id)}
                            >
                              {" "}
                              Rating
                            </button>
                          </div>
                        ),
                      },
                      {
                        title: "Edit",
                        field: "status",
                        render: (row) => (
                          <div>
                            <button
                              className="btn btn-success btn-sm"
                              onClick={() => abce(row.id)}
                            >
                              {" "}
                              Edit
                            </button>
                          </div>
                        ),
                      },
                    ]}
                    data={plumber}
                    options={{
                      actionsColumnIndex: -1,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(ViewPlumber);
