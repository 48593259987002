import React, { Component } from "react";
import axios from "axios";
import { checkAuth } from "../../utils/auth";
import { post, authformpost, authget, authpost } from "../../utils/service";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { imageValid } from "../../utils/imageValid";
import { Link, withRouter } from "react-router-dom";
import queryString from "query-string";

export default class EditPlumber extends Component {
  constructor(props) {
    const parsed = queryString.parse(props.location.search);
    const { id } = parsed;
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      id: id,
      loading: false,
      w_name: "",
      w_category: "",
      w_address: "",
      w_city_id: "",
      w_occupatation: "",
      w_phone_no: "",
      w_whatapp_no: "",
      w_email_id: "",
      w_remerks: "",
      s_category_id: "",
      cityarray: [],
      category: [],
      sData: [],
    };
    this.validator = new SimpleReactValidator({
      messages: {
        in: "Passwords need to match!",
      },
    });
  }

  //   ViewoneServiceProvider

  componentDidMount() {
    // window.scrollTo(0, 0);
    authget(`/api/viewCity`)
      .then((response) => {
        // console.log("uuuuu", response.data);
        this.setState({
          cityarray: response.data.city?.filter((gs) => gs?.city_status == 1),
        });
        //  console.log(response.data.coupon_details)
      })
      .catch(function (error) {
        console.log(error);
      });

    authget(`/api/ViewSpCatConsaltent`)
      .then((response) => {
        // console.log("uuuuu", response.data);
        this.setState({ category: response.data.data });
        //  console.log(response.data.coupon_details)
      })
      .catch(function (error) {
        console.log(error);
      });

    authget(`/api/ViewoneServiceProvider?worker_id=${this.state.id}`)
      .then((response) => {
        this.setState({
          w_name: response.data.data.w_name,
          s_category_id: response.data.data.s_category_id,
          w_city_id: response.data.data.w_city_id,
          w_phone_no: response.data.data.w_phone_no,
          w_whatapp_no: response.data.w_whatapp_no,
          w_remerks: response.data.data.w_remerks,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    var formdata = {
      worker_id: this.state.id,
      w_name: this.state.w_name,
      w_category: this.state.w_category,
      w_address: this.state.w_category,
      w_city_id: this.state.w_city_id,
      w_occupatation: this.state.w_occupatation,
      w_phone_no: this.state.w_phone_no,
      w_whatapp_no: this.state.w_whatapp_no,
      w_email_id: this.state.w_email_id,
      w_remerks: this.state.w_remerks,
      s_category_id: this.state.s_category_id,
    };

    // console.log("uyuy", formdata);

    if (this.validator.allValid()) {
      authpost("/api/updateServiceProvider", formdata)
        .then((data) => {
          // console.log(data.data);
          if (data.data.success === true) {
            toast.dark("Service Providers Update Successful", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 3000,
            });
            this.props.history.push("/view-plumber");
            this.setState({ loading: false });
          } else {
            toast.dark(data.data.response, {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 3000,
            });
            this.setState({ loading: false });
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    // console.log(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <div>
        <div className="content">
          <div className="container-fluid">
            <div className="row page-title">
              <div className="col-md-12">
                <nav aria-label="breadcrumb" className="float-right mt-1">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Edit Service Providers
                    </li>
                  </ol>
                </nav>
                <h4 className="mb-1 mt-0">Edit Service Providers</h4>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    {/* <h4 className="header-title mt-0 mb-1">Bootstrap Validation - Normal</h4>
                                        <p className="sub-header">Provide valuable, actionable feedback to your users with HTML5 form validation–available in all our supported browsers.</p> */}

                    <form className="needs-validation">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label for="validationCustom02">
                              {" "}
                              Service Providers name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="w_name"
                              name="w_name"
                              placeholder=" Service Providers name"
                              onChange={this.handleChange}
                              value={this.state.w_name}
                              required
                            />
                            {this.validator.message(
                              "Service Providers name",
                              this.state.w_name,
                              "required",
                              { className: "text-danger" }
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label for="validationCustom02">
                              Select Service Providers occupation{" "}
                            </label>

                            <select
                              name="s_category_id"
                              id="s_category_id"
                              className="form-control"
                              onChange={this.handleChange}
                              required
                            >
                              <option value="" disabled>
                                Select Service Providers occupation{" "}
                              </option>
                              {this.state.category.map((obj, i) => (
                                <option
                                  value={obj?.id}
                                  selected={obj?.id == this.state.s_category_id}
                                >
                                  {obj?.s_category_name}
                                </option>
                              ))}
                            </select>

                            {this.validator.message(
                              " Select Service Providers occupation",
                              this.state.s_category_id,
                              "required",
                              { className: "text-danger" }
                            )}
                          </div>
                        </div>

                        {/* <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label for="validationCustom02">
                              {" "}
                              Service Providers category{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="w_category"
                              name="w_category"
                              placeholder="Service Providers category"
                              onChange={this.handleChange}
                              value={this.state.w_category}
                            />
                         
                          </div>
                        </div> */}
                        {/* <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label for="validationCustom02">
                              {" "}
                              Service Providers address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="w_address"
                              name="w_address"
                              placeholder="Service Providers address"
                              onChange={this.handleChange}
                              value={this.state.w_address}
                            />
                            {this.validator.message(
                              "password",
                              this.state.password,
                              "required|min:8|max:120",
                              { className: "text-danger" }
                            )}
                          </div>
                        </div> */}
                        {/* <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label for="validationCustom02">
                              {" "}
                              Service Providers occupation{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="w_occupatation"
                              name="w_occupatation"
                              placeholder=" Service Providers occupation"
                              onChange={this.handleChange}
                              value={this.state.w_occupatation}
                              required
                            />
                      
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label for="validationCustom02">
                              {" "}
                              Service Providers phone number
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="w_phone_no"
                              name="w_phone_no"
                              placeholder="Service Providers phone number"
                              onChange={this.handleChange}
                              value={this.state.w_phone_no}
                              required
                            />
                            {this.validator.message(
                              " Service Providers phone number",
                              this.state.w_phone_no,
                              "required",
                              { className: "text-danger" }
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label for="validationCustom02">
                              {" "}
                              Service Providers whatapp no{" "}
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="w_whatapp_no"
                              name="w_whatapp_no"
                              placeholder=" Service Providers whatapp no"
                              onChange={this.handleChange}
                              value={this.state.w_whatapp_no}
                            />
                            {/* {this.validator.message(
                              "c_password",
                              this.state.c_password,
                              `required|in:${this.state.password}`,
                              { className: "text-danger" },
                              { messages: { in: "Passwords need to match!" } }
                            )} */}
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label for="validationCustom02">
                              {" "}
                              Plumber email id
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="w_email_id"
                              name="w_email_id"
                              placeholder=" Plumber email id"
                              onChange={this.handleChange}
                              value={this.state.w_email_id}
                            />
                             {this.validator.message(
                              "password",
                              this.state.password,
                              "required|min:8|max:120",
                              { className: "text-danger" }
                            )} 
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label for="validationCustom02">
                              {" "}
                              Service Providers remarks{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="w_remerks"
                              name="w_remerks"
                              placeholder=" Service Providers remarks"
                              onChange={this.handleChange}
                              value={this.state.w_remerks}
                            />
                            {/* {this.validator.message(
                              "c_password",
                              this.state.c_password,
                              `required|in:${this.state.password}`,
                              { className: "text-danger" },
                              { messages: { in: "Passwords need to match!" } }
                            )} */}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label for="validationCustom02">Select City </label>

                            <select
                              name="w_city_id"
                              id="w_city_id"
                              className="form-control"
                              onChange={this.handleChange}
                              required
                            >
                              <option value="" disabled>
                                select city{" "}
                              </option>
                              {this.state.cityarray.map((obj, i) => (
                                <option
                                  value={obj?.id}
                                  selected={obj?.id == this.state.w_city_id}
                                >
                                  {obj?.city_name}
                                </option>
                              ))}
                            </select>

                            {this.validator.message(
                              "City",
                              this.state.w_city_id,
                              "required",
                              { className: "text-danger" }
                            )}
                          </div>
                        </div>
                      </div>

                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={this.handleSubmit}
                        disabled={this.state.loading}
                      >
                        {this.state.loading ? "Loading..." : "Submit"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
