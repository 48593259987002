import React, { Component } from 'react'
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { post, authformpost, authget } from '../../utils/service'
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { imageValid } from '../../utils/imageValid';
import { Link, withRouter } from 'react-router-dom'
// import BackDrop1 from '../Loader/BackDrop1';
export default class Add_user extends Component {
    constructor(props) {
        super(props)
        window.scrollTo(0, 0);
        this.state = {
            loading: false,
            name: '',
            email: '',
            phone: '',
            address: '',
            password: '',
            c_password: '',
        }
        this.validator = new SimpleReactValidator({
            messages: {
              in: 'Passwords need to match!'
            }
        })

    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        var formdata = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            password: this.state.password,
            address: this.state.address,
        };

        if (this.validator.allValid()) {
            post('/api/register1', formdata)
                .then((data) => {
                    console.log(data.data)
                    if(data.data.status === true){
                        toast.dark('Register Successful',
                            { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                        this.props.history.push('/view_user');
                        this.setState({ loading: false });
                    }else{
                        toast.dark(data.data.response,
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                        this.setState({ loading: false });
                    }
                   
                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        return (
            <div>
                <div className="content">
                    <div className="container-fluid">
                        <div className="row page-title">
                            <div className="col-md-12">
                                <nav aria-label="breadcrumb" className="float-right mt-1">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add User</li>
                                    </ol>
                                </nav>
                                <h4 className="mb-1 mt-0">Add User</h4>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        {/* <h4 className="header-title mt-0 mb-1">Bootstrap Validation - Normal</h4>
                                        <p className="sub-header">Provide valuable, actionable feedback to your users with HTML5 form validation–available in all our supported browsers.</p> */}

                                        <form className="needs-validation" >
                                            <div className="form-group mb-3">
                                                <label for="validationCustom01">Enter User Name </label>
                                                <input type="text" className="form-control" id="name" name="name" placeholder="Enter User Name" onChange={this.handleChange} value={this.state.name} />
                                                {this.validator.message('name', this.state.name, 'required', { className: 'text-danger' })}
                                            </div>
                                            
                                            <div className="form-group mb-3">
                                                <label for="validationCustom02">Email Id</label>
                                                <input type="text" className="form-control" id="email" name="email" placeholder="Enter Email Id" onChange={this.handleChange} value={this.state.email} />
                                                {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })}
                                              
                                            </div>
                                            <div className="form-group mb-3">
                                                <label for="validationCustom02"> Phone Number</label>
                                                <input type="number" className="form-control" id="phone" name="phone" placeholder="Enter Phone Number" onChange={this.handleChange} value={this.state.phone} />
                                                {this.validator.message('phone', this.state.phone, 'required|max:10', { className: 'text-danger' })}
                                            </div>
                                            <div className="form-group mb-3">
                                                <label for="validationCustom02"> Address</label>
                                                <input type="text" className="form-control" id="address" name="address" placeholder="Address" onChange={this.handleChange} value={this.state.address} />
                                                {this.validator.message('address', this.state.address, 'required', { className: 'text-danger' })}

                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3">
                                                        <label for="validationCustom02"> Password</label>
                                                        <input type="password" className="form-control" id="password" name="password" placeholder="Password" onChange={this.handleChange} value={this.state.password} />
                                                        {this.validator.message('password', this.state.password, 'required|min:8|max:120', { className: 'text-danger' })}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3">
                                                        <label for="validationCustom02"> Confirm Password </label>
                                                        <input type="password" className="form-control" id="c_password" name="c_password" placeholder="Confirm Password"  onChange={this.handleChange} value={this.state.c_password}/>
                                                        {this.validator.message('c_password', this.state.c_password, `required|in:${this.state.password}`,{ className: 'text-danger' }, {messages: {in: 'Passwords need to match!'}})}
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="btn btn-primary" type="submit" onClick={this.handleSubmit}   disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Register Here'}</button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                         </div>

                    </div>
                </div>
            </div>
        )
    }
}
