import React, { Component } from 'react'
import { DropzoneArea } from 'material-ui-dropzone'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Link, withRouter } from 'react-router-dom'
import { AttachFile, AudioTrack, Description, PictureAsPdf, Theaters } from '@material-ui/icons';
const theme = createMuiTheme({
    overrides: {
        MuiDropzoneSnackbar: {
            errorAlert: {
                backgroundColor: "#AFA",
                color: "#000"
            },
            successAlert: {
                backgroundColor: "#FAA",
                color: "#000"
            },

        },
    }
});

export default class View_order_details extends Component {
    render() {
        return (
            <div>
                <div className="content">
                    <div className="container-fluid">
                        <div className="row page-title">
                            <div className="col-md-12">
                                <nav aria-label="breadcrumb" className="float-right mt-1">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="#">Traking Order</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Home</li>
                                    </ol>
                                </nav>
                                <h4 className="mb-1 mt-0">Traking Order</h4>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-activity" role="tabpanel" aria-labelledby="pills-activity-tab">
                                                <h5 className="mt-3">Traking Order</h5>
                                                <div className="left-timeline mt-3 mb-3 pl-4">
                                                    <ul className="list-unstyled events mb-0">
                                                       
                                                        <li className="event-list">
                                                            <div className="pb-4">
                                                                <div className="media">
                                                                    <div className="event-date text-center mr-4">
                                                                        <div className="bg-soft-primary p-1 rounded text-primary font-size-14">
                                                                            02 hours ago
                                                                        </div>
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h6 className="font-size-15 mt-0 mb-1">Designing Shreyu Admin</h6>
                                                                        <p className="text-muted font-size-14">Shreyu Admin - A responsive admin and dashboard template</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="event-list">
                                                            <div className="pb-4">
                                                                <div className="media">
                                                                    <div className="event-date text-center mr-4">
                                                                        <div className="bg-soft-primary p-1 rounded text-primary font-size-14">
                                                                            21 hours ago
                                                                        </div>
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h6 className="font-size-15 mt-0 mb-1">UX and UI for Ubold Admin</h6>
                                                                        <p className="text-muted font-size-14">Ubold Admin - A responsive admin and dashboard template</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="event-list">
                                                            <div className="pb-4">
                                                                <div className="media">
                                                                    <div className="event-date text-center mr-4">
                                                                        <div className="bg-soft-primary p-1 rounded text-primary font-size-14">
                                                                            22 hours ago
                                                                        </div>
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h6 className="font-size-15 mt-0 mb-1">UX and UI for Hyper Admin</h6>
                                                                        <p className="text-muted font-size-14">Hyper Admin - A responsive admin and dashboard template</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="event-list">
                                                            <div className="pb-4">
                                                                <div className="media">
                                                                    <div className="event-date text-center mr-4">
                                                                        <div className="bg-soft-primary p-1 rounded text-primary font-size-14">
                                                                            22 hours ago
                                                                        </div>
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h6 className="font-size-15 mt-0 mb-1">UX and UI for Hyper Admin</h6>
                                                                        <p className="text-muted font-size-14">Hyper Admin - A responsive admin and dashboard template</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="event-list">
                                                            <div className="pb-4">
                                                                <div className="media">
                                                                    <div className="event-date text-center mr-4">
                                                                        <div className="bg-soft-primary p-1 rounded text-primary font-size-14">
                                                                            22 hours ago
                                                                        </div>
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h6 className="font-size-15 mt-0 mb-1">UX and UI for Hyper Admin</h6>
                                                                        <p className="text-muted font-size-14">Hyper Admin - A responsive admin and dashboard template</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="event-list">
                                                            <div className="pb-4">
                                                                <div className="media">
                                                                    <div className="event-date text-center mr-4">
                                                                        <div className="bg-soft-primary p-1 rounded text-primary font-size-14">
                                                                            22 hours ago
                                                                        </div>
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h6 className="font-size-15 mt-0 mb-1">UX and UI for Hyper Admin</h6>
                                                                        <p className="text-muted font-size-14">Hyper Admin - A responsive admin and dashboard template</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>

                                                    </ul>
                                                </div>


                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>


            </div>
        )
    }
}
