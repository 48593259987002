import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { checkAuth } from './../../utils/auth'
import { connect } from 'react-redux'
import { compose } from "redux";
import { updateProfile } from "./../../services/Actions/actions"
import * as CONSTANT from '../../utils/constant';
function Header(props) {

    const signout = () => {
        checkAuth.signout();
        localStorage.removeItem('consaltentToken');
        localStorage.removeItem('consaltentName');
        localStorage.removeItem('consaltentEmail');
        localStorage.removeItem('consaltentPhone');
        localStorage.removeItem('consaltent_id');
        window.location.href = 'login';
        window.location.reload();
    }


    if (props.history.location.pathname == '/login' || props.history.location.pathname == '/register' || props.history.location.pathname == '/forgotpassword') {
        return null
    } else {
        return (
            <div>
                <div className="navbar navbar-expand flex-column flex-md-row navbar-custom">
                    <div className="container-fluid">
                        <Link to="/" className="navbar-brand mr-0 mr-md-2 logo">
                            <span className="logo-lg">
                                <img src="assets/img/brand/logo.jpg" alt="" style={{ width: "190px" }} />

                            </span>
                            <span className="logo-sm">
                                <img src="assets/img/brand/logo.jpg" alt="" />
                            </span>
                        </Link>

                        <ul className="navbar-nav bd-navbar-nav flex-row list-unstyled menu-left mb-0">
                            <li className="">
                                <button className="button-menu-mobile open-left disable-btn">
                                    <i data-feather="menu" className="menu-icon"></i>
                                    <i data-feather="x" className="close-icon"></i>
                                </button>
                            </li>
                        </ul>

                        <ul className="navbar-nav flex-row ml-auto d-flex list-unstyled topnav-menu float-right mb-0">
                            <li className="dropdown d-none d-lg-block" data-toggle="tooltip" data-placement="left" title="Change language">
                                <a className="nav-link dropdown-toggle mr-0" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                    <i data-feather="globe"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <a href="javascript:void(0);" className="dropdown-item notify-item"> <img src="assets/images/flags/germany.jpg" alt="user-image" className="mr-2" height="12" /> <span className="align-middle">German</span> </a>

                                    <a href="javascript:void(0);" className="dropdown-item notify-item"> <img src="assets/images/flags/italy.jpg" alt="user-image" className="mr-2" height="12" /> <span className="align-middle">Italian</span> </a>

                                    <a href="javascript:void(0);" className="dropdown-item notify-item"> <img src="assets/images/flags/spain.jpg" alt="user-image" className="mr-2" height="12" /> <span className="align-middle">Spanish</span> </a>

                                    <a href="javascript:void(0);" className="dropdown-item notify-item"> <img src="assets/images/flags/russia.jpg" alt="user-image" className="mr-2" height="12" /> <span className="align-middle">Russian</span> </a>
                                </div>
                            </li>

                            <li className="dropdown notification-list" data-toggle="tooltip" data-placement="left" title="Profile">
                                <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                    <i data-feather="bell"></i>
                                    {Object.keys(props.data.show_profile.user).length != 0 ?
                                        <>
                                            <img src={`${CONSTANT.URL}/storage/${props.data.show_profile.user.user_details.profile_image}`} className="avatar-sm rounded-circle mr-2" alt="Shreyu" />

                                        </>
                                        : <img src="assets/images/users/avatar-7.jpg" className="avatar-sm rounded-circle mr-2" alt="Shreyu" />
                                    }
                                </a>
                                <div className="dropdown-menu dropdown-menu-right dropdown-lg">
                                    <div className="dropdown-item noti-title border-bottom">
                                        <h5 className="m-0 font-size-16">
                                        Consultant
                                        </h5>
                                    </div>

                                    <div className="slimscroll noti-scroll">
                                        <Link to="viewprofile" className="dropdown-item notify-item border-bottom">
                                            <div className="notify-icon bg-primary"><i className="uil uil-user-plus"></i></div>
                                            <p className="notify-details">Profile</p>
                                        </Link>
                                        <a href="javascript:void(0);" className="dropdown-item notify-item border-bottom"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                signout()
                                            }}
                                        >
                                            <div className="notify-icon bg-primary">  <i className="icon-dual icon-xs mr-2"></i></div>
                                            <p className="notify-details">Logout</p>
                                        </a>



                                    </div>
                                </div>
                            </li>

                            <li className="dropdown notification-list" data-toggle="tooltip" data-placement="left" title="Settings">
                                <a href="javascript:void(0);" className="nav-link right-bar-toggle">
                                    <i data-feather="settings"></i>
                                </a>
                            </li>

                            <li className="dropdown notification-list align-self-center profile-dropdown">
                                <a className="nav-link dropdown-toggle nav-user mr-0" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                    <div className="media user-profile">
                                        <img src="assets/images/users/avatar-7.jpg" alt="user-image" className="rounded-circle align-self-center" />
                                        <div className="media-body text-left">
                                            <h6 className="pro-user-name ml-2 my-0">
                                                <span>Shreyu N</span>
                                                <span className="pro-user-desc text-muted d-block mt-1">Administrator </span>
                                            </h6>
                                        </div>
                                        <span data-feather="chevron-down" className="ml-2 align-self-center"></span>
                                    </div>
                                </a>
                                <div className="dropdown-menu profile-dropdown-items dropdown-menu-right">
                                    <a href="pages-profile.html" className="dropdown-item notify-item">
                                        <i data-feather="user" className="icon-dual icon-xs mr-2"></i>
                                        <span>My Account</span>
                                    </a>

                                    <a href="javascript:void(0);" className="dropdown-item notify-item">
                                        <i data-feather="settings" className="icon-dual icon-xs mr-2"></i>
                                        <span>Settings</span>
                                    </a>

                                    <a href="javascript:void(0);" className="dropdown-item notify-item">
                                        <i data-feather="help-circle" className="icon-dual icon-xs mr-2"></i>
                                        <span>Support</span>
                                    </a>

                                    <Link to="viewprofile" className="dropdown-item notify-item">
                                        <i data-feather="lock" className="icon-dual icon-xs mr-2"></i>
                                        <span>Lock Screen</span>
                                    </Link>

                                    <div className="dropdown-divider"></div>

                                    <Link to="/" className="dropdown-item notify-item">
                                        <i data-feather="log-out" className="icon-dual icon-xs mr-2"></i>
                                        <span>Logout</span>
                                    </Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    console.log(state)
    return {
        data: state
    }
}
const mapDispatchToProps = dispatch => ({
    updateProfile: () => { dispatch(updateProfile()) },
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Header);