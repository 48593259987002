import React from 'react'
import {Link, withRouter} from 'react-router-dom'

 function Footer(props) {
    if (props.history.location.pathname == '/login' || props.history.location.pathname == '/register' || props.history.location.pathname == '/forgotpassword') {
     return null
    }else{
        return (
            <>
            <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">2021 © All Rights Reserved. Grihanirmanguide </div>
                </div>
            </div>
            </footer>
            </>
        )

    }
    
}

export default withRouter(Footer)