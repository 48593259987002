import React from 'react'

export default function AddProfile() {
    return (
        <div>
            <div className="content">
                <div className="container-fluid">
                    <div className="row page-title">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb" className="float-right mt-1">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#">Shreyu</a></li>
                                    <li className="breadcrumb-item"><a href="#">Forms</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Basic</li>
                                </ol>
                            </nav>
                            <h4 className="mb-1 mt-0">Basic Forms</h4>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="header-title mt-0">Input Types</h4>
                                    <p className="sub-header">
                                        Most common form control, text-based input fields. Includes support for all HTML5 types: <code>text</code>, <code>password</code>, <code>datetime</code>, <code>datetime-local</code>, <code>date</code>,
                                        <code>month</code>, <code>time</code>, <code>week</code>, <code>number</code>, <code>email</code>, <code>url</code>, <code>search</code>, <code>tel</code>, and <code>color</code>.
                                    </p>

                                    <form className="form-horizontal">
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label" for="simpleinput">Text</label>
                                                    <div className="col-lg-10">
                                                        <input type="text" className="form-control" id="simpleinput" value="Some text value..." />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label" for="example-email">Email</label>
                                                    <div className="col-lg-10">
                                                        <input type="email" id="example-email" name="example-email" className="form-control" placeholder="Email" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label" for="example-password">Password</label>
                                                    <div className="col-lg-10">
                                                        <input type="password" className="form-control" id="example-password" value="password" />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label" for="example-placeholder">Placeholder</label>
                                                    <div className="col-lg-10">
                                                        <input type="text" className="form-control" placeholder="placeholder" id="example-placeholder" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label" for="example-textarea">Text area</label>
                                                    <div className="col-lg-10">
                                                        <textarea className="form-control" rows="5" id="example-textarea"></textarea>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label">Readonly</label>
                                                    <div className="col-lg-10">
                                                        <input type="text" className="form-control" readonly="" value="Readonly value" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label">Disabled</label>
                                                    <div className="col-lg-10">
                                                        <input type="text" className="form-control" disabled="" value="Disabled value" />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label" for="example-static">Static control</label>
                                                    <div className="col-lg-10">
                                                        <input type="text" readonly className="form-control-plaintext" id="example-static" value="email@example.com" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label" for="example-helping">Helping text</label>
                                                    <div className="col-lg-10">
                                                        <input type="text" className="form-control" id="example-helping" placeholder="Helping text" />
                                                        <span className="help-block"><small>A block of help text that breaks onto a new line and may extend beyond one line.</small></span>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label">Input Select</label>
                                                    <div className="col-lg-10">
                                                        <select className="form-control custom-select">
                                                            <option>1</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                            <option>5</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label" for="example-fileinput">Default file input</label>
                                                    <div className="col-lg-10">
                                                        <input type="file" className="form-control" id="example-fileinput" />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label" for="example-date">Date</label>
                                                    <div className="col-lg-10">
                                                        <input className="form-control" id="example-date" type="date" name="date" />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label" for="example-month">Month</label>
                                                    <div className="col-lg-10">
                                                        <input className="form-control" id="example-month" type="month" name="month" />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label" for="example-time">Time</label>
                                                    <div className="col-lg-10">
                                                        <input className="form-control" id="example-time" type="time" name="time" />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label" for="example-week">Week</label>
                                                    <div className="col-lg-10">
                                                        <input className="form-control" id="example-week" type="week" name="week" />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label" for="example-number">Number</label>
                                                    <div className="col-lg-10">
                                                        <input className="form-control" id="example-number" type="number" name="number" />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label">URL</label>
                                                    <div className="col-lg-10">
                                                        <input className="form-control" type="url" name="url" />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label">Search</label>
                                                    <div className="col-lg-10">
                                                        <input className="form-control" type="search" name="search" />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label">Tel</label>
                                                    <div className="col-lg-10">
                                                        <input className="form-control" type="tel" name="tel" />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label" for="example-color">Color</label>
                                                    <div className="col-lg-10">
                                                        <input className="form-control" id="example-color" type="color" name="color" value="#5369f8" />
                                                    </div>
                                                </div>

                                                <div className="form-group row mb-0">
                                                    <label className="col-lg-2 col-form-label" for="example-range">Range</label>
                                                    <div className="col-lg-10">
                                                        <input className="custom-range mt-1" id="example-range" type="range" name="range" min="0" max="10" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                

                    <div className="row">
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="header-title mt-0">Select menu</h4>
                                    <p className="sub-header">Custom <code>&lt;select&gt;</code> menus need only a custom className, <code>.custom-select</code> to trigger the custom styles.</p>

                                    <select className="custom-select mb-2">
                                        <option selected>Open this select menu</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                    <select className="custom-select custom-select-lg mb-2">
                                        <option selected>Open this select menu</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                    <select className="custom-select custom-select-sm">
                                        <option selected>Open this select menu</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>

                                    <div className="mt-4">
                                        <h4 className="header-title">Switches</h4>
                                        <p className="sub-header">
                                            A switch has the markup of a custom checkbox but uses the
                                            <code>.custom-switch</code> className to render a toggle switch. Switches also support the <code>disabled</code> attribute.
                                        </p>

                                        <div className="custom-control custom-switch mb-2">
                                            <input type="checkbox" className="custom-control-input" id="customSwitch1" />
                                            <label className="custom-control-label" for="customSwitch1">Toggle this switch element</label>
                                        </div>
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" disabled id="customSwitch2" />
                                            <label className="custom-control-label" for="customSwitch2">Disabled switch element</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="header-title mt-0">Checkboxes</h4>
                                    <div className="mt-3">
                                        <div className="custom-control custom-checkbox mb-2">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" checked />
                                            <label className="custom-control-label" for="customCheck1">Check this custom checkbox</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="customCheck2" />
                                            <label className="custom-control-label" for="customCheck2">Check this custom checkbox</label>
                                        </div>
                                    </div>

                                    <h4 className="font-size-15 mt-3">Radios</h4>
                                    <div className="">
                                        <div className="custom-control custom-radio mb-2">
                                            <input type="radio" id="customRadio1" name="customRadio" className="custom-control-input" />
                                            <label className="custom-control-label" for="customRadio1">Toggle this custom radio</label>
                                        </div>
                                        <div className="custom-control custom-radio">
                                            <input type="radio" id="customRadio2" name="customRadio" className="custom-control-input" checked />
                                            <label className="custom-control-label" for="customRadio2">Or toggle this other custom radio</label>
                                        </div>
                                    </div>

                                    <h4 className="font-size-15 mt-3">Disabled</h4>
                                    <div className="">
                                        <div className="custom-control custom-checkbox mb-2">
                                            <input type="checkbox" className="custom-control-input" id="customCheckDisabled1" disabled />
                                            <label className="custom-control-label" for="customCheckDisabled1">Check this custom checkbox</label>
                                        </div>

                                        <div className="custom-control custom-radio">
                                            <input type="radio" name="radioDisabled" id="customRadioDisabled2" className="custom-control-input" disabled />
                                            <label className="custom-control-label" for="customRadioDisabled2">Toggle this custom radio</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                

                    <div className="row">
                        <div className="col-xl-6">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="mb-3 header-title mt-0">Basic Example</h4>

                                    <form>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Email address</label>
                                            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
                                            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputPassword1">Password</label>
                                            <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="checkmeout0" />
                                                <label className="custom-control-label" for="checkmeout0">Check me out !</label>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </form>
                                </div>
                            
                            </div>
                        
                        </div>
                

                        <div className="col-xl-6">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="mb-3 header-title mt-0">Horizontal Form</h4>

                                    <form className="form-horizontal">
                                        <div className="form-group row mb-3">
                                            <label for="inputEmail3" className="col-3 col-form-label">Email</label>
                                            <div className="col-9">
                                                <input type="email" className="form-control" id="inputEmail3" placeholder="Email" />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-3">
                                            <label for="inputPassword3" className="col-3 col-form-label">Password</label>
                                            <div className="col-9">
                                                <input type="password" className="form-control" id="inputPassword3" placeholder="Password" />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-3">
                                            <label for="inputPassword5" className="col-3 col-form-label">Re Password</label>
                                            <div className="col-9">
                                                <input type="password" className="form-control" id="inputPassword5" placeholder="Retype Password" />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-3 justify-content-end">
                                            <div className="col-9">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="checkmeout" />
                                                    <label className="custom-control-label" for="checkmeout">Check me out !</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group mb-0 justify-content-end row">
                                            <div className="col-9">
                                                <button type="submit" className="btn btn-info">Sign in</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="mb-1 header-title mt-0">Inline Form</h4>

                                    <p className="sub-header">
                                        Use the <code>.form-inline</code> className to display a series of labels, form controls, and buttons on a single horizontal row. Form controls within inline forms vary slightly from their default states. Controls
                                        only appear inline in viewports that are at least 576px wide to account for narrow viewports on mobile devices.
                                    </p>

                                    <form className="form-inline">
                                        <div className="form-group mr-3">
                                            <label for="staticEmail2" className="sr-only">Email</label>
                                            <input type="text" readonly className="form-control-plaintext" id="staticEmail2" value="email@example.com" />
                                        </div>
                                        <div className="form-group mr-3">
                                            <label for="inputPassword2" className="sr-only">Password</label>
                                            <input type="password" className="form-control" id="inputPassword2" placeholder="Password" />
                                        </div>
                                        <button type="submit" className="btn btn-primary">Confirm identity</button>
                                    </form>

                                    <h6 className="font-13 mt-3 font-weight-semibold">Auto-sizing</h6>

                                    <form>
                                        <div className="form-row align-items-center">
                                            <div className="col-auto">
                                                <label className="sr-only" for="inlineFormInput">Name</label>
                                                <input type="text" className="form-control mb-2" id="inlineFormInput" placeholder="Jane Doe" />
                                            </div>
                                            <div className="col-auto">
                                                <label className="sr-only" for="inlineFormInputGroup">Username</label>
                                                <div className="input-group mb-2">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">@</div>
                                                    </div>
                                                    <input type="text" className="form-control" id="inlineFormInputGroup" placeholder="Username" />
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="custom-control custom-checkbox mb-2">
                                                    <input type="checkbox" className="custom-control-input" id="autoSizingCheck" />
                                                    <label className="custom-control-label" for="autoSizingCheck">Remember me</label>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <button type="submit" className="btn btn-primary mb-2">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                
                </div>
            
            </div>


            
        </div>
    )
}
