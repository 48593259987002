import Sidebar from '../components/layout_component/Sidebar'
import {connect} from 'react-redux'


const mapStateToProps=state=>({
    
})
const mapDispatchToProps=dispatch=>({
    

})
export default connect(mapStateToProps,mapDispatchToProps)(Sidebar)