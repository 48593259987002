import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { updateProfile } from "./../../services/Actions/actions";
import * as CONSTANT from "../../utils/constant";

function Sidebar(props) {
  console.log(Object.keys(props.data.show_profile.user).length);

  if (
    props.history.location.pathname == "/login" ||
    props.history.location.pathname == "/register" ||
    props.history.location.pathname == "/forgotpassword"
  ) {
    return null;
  } else {
    return (
      <div>
        <div className="left-side-menu">
          <div className="media user-profile mt-2 mb-2">
            {Object.keys(props.data.show_profile.user).length != 0 ? (
              <>
                <img
                  src={`${CONSTANT.URL}/storage/${props.data.show_profile.user.user_details.profile_image}`}
                  className="avatar-sm rounded-circle mr-2"
                  alt="Shreyu"
                />
                <img
                  src={`${CONSTANT.URL}/storage/${props.data.show_profile.user.user_details.profile_image}`}
                  className="avatar-xs rounded-circle mr-2"
                  alt="Shreyu"
                />
              </>
            ) : null}

            <div className="media-body">
              {Object.keys(props.data.show_profile.user).length != 0 ? (
                <h6 className="pro-user-name mt-0 mb-0">
                  {props.data.show_profile.user.user.name}
                </h6>
              ) : null}
              <span className="pro-user-desc">Consultant</span>
            </div>
            <div className="dropdown align-self-center profile-dropdown-menu">
              <a
                className="dropdown-toggle mr-0"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <span data-feather="chevron-down"></span>
              </a>
              <div className="dropdown-menu profile-dropdown">
                <a
                  href="pages-profile.html"
                  className="dropdown-item notify-item"
                >
                  <i data-feather="user" className="icon-dual icon-xs mr-2"></i>
                  <span>My Account</span>
                </a>

                <a
                  href="javascript:void(0);"
                  className="dropdown-item notify-item"
                >
                  <i
                    data-feather="settings"
                    className="icon-dual icon-xs mr-2"
                  ></i>
                  <span>Settings</span>
                </a>

                <a
                  href="javascript:void(0);"
                  className="dropdown-item notify-item"
                >
                  <i
                    data-feather="help-circle"
                    className="icon-dual icon-xs mr-2"
                  ></i>
                  <span>Support</span>
                </a>

                <a
                  href="pages-lock-screen.html"
                  className="dropdown-item notify-item"
                >
                  <i data-feather="lock" className="icon-dual icon-xs mr-2"></i>
                  <span>Lock Screen</span>
                </a>

                <div className="dropdown-divider"></div>

                <a
                  href="javascript:void(0);"
                  className="dropdown-item notify-item"
                >
                  <i
                    data-feather="log-out"
                    className="icon-dual icon-xs mr-2"
                  ></i>
                  <span>Logout</span>
                </a>
              </div>
            </div>
          </div>
          <div className="sidebar-content">
            <div id="sidebar-menu" className="slimscroll-menu">
              <ul className="metismenu" id="menu-bar">
                {/* <li className="menu-title">Navigation</li> */}
                <li>
                  <Link to="/">
                    <i data-feather="home"></i>

                    <span>Dashboard</span>
                  </Link>
                </li>
                <li>
                  <Link to="view_user">
                    <i data-feather="home"></i>

                    <span>View User </span>
                  </Link>
                </li>
                <li>
                  <Link to="pre_booking">
                    <i data-feather="home"></i>

                    <span>Readymade Booking</span>
                  </Link>
                </li>{" "}
                <li>
                  <Link to="customize_booking">
                    <i data-feather="home"></i>

                    <span> Customize Booking </span>
                  </Link>
                </li>
                <li>
                  <Link to="view-plumber">
                    <i data-feather="home"></i>

                    <span> Service Providers </span>
                  </Link>
                </li>
                <li>
                  <a href="index.html">
                    <i data-feather="home"></i>

                    <span> Data Report </span>
                  </a>
                </li>{" "}
                <li>
                  <Link to="offer">
                    <i data-feather="home"></i>

                    <span> Offer&Benefits </span>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    data: state,
  };
};
const mapDispatchToProps = (dispatch) => ({
  updateProfile: () => {
    dispatch(updateProfile());
  },
});
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Sidebar);
