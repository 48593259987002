import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'
import { toast } from 'react-toastify';
import { post, authpost, authformpost, authget } from '../../utils/service'
import 'react-toastify/dist/ReactToastify.css';
import { Link, withRouter } from 'react-router-dom'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import * as CONSTANT from '../../utils/constant';
import queryString from 'query-string';
import moment from 'moment';
toast.configure();

export default function Payment_transaction(props) {
    let param = props.location.search;
    const parsed = queryString.parse(param);
    const [payment_detail, setPayment_details] = useState([])
   
    const [payment_value, setPayment_value] = useState('');
    const [purpose, setPurpose] = useState('');

   
    const [user_id, setUser_id] = useState(parsed.id);
    useEffect(() => {
        window.scrollTo(0, 0);
        payment_details();
    }, []);

    const paymentSubmit = (event) => {
        event.preventDefault()
        const data = {
            user_id,
            payment_value,
            purpose
        }
        authpost(`/api/payment_submit`, data)
            .then((response) => {
                console.log(response.data);
                payment_details();
                window.location.reload();
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const payment_details = () => {
        authget(`/api/payment_list?user_id=${user_id}`)
            .then((response) => {
                console.log(response.data);
                setPayment_details(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    return (
        <div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row page-title">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb" className="float-right mt-1">
                                <ol className="breadcrumb">

                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Users</li>
                                </ol>
                            </nav>
                            <h4 className="mb-1 mt-0">Payment Details</h4>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-xl-12">
                            <div className="card">
                                <div className="card-body p-3">
                                    <form>
                                        <h5 className="">Enter Payment Value</h5>
                                        <input type="number" className="form-control" name="payment_value" id="payment_value" value={payment_value} onChange={(e) => setPayment_value(e.target.value)} placeholder="Enter Payment Amount"></input><br />
                                        <h5 className="">Enter Purpose</h5>
                            
                                        <input type="text" className="form-control" name="purpose" id="purpose" value={purpose} onChange={(e) => setPurpose(e.target.value)} placeholder="Enter Purpose"></input><br />

                                        <button className="btn btn-success " onClick={paymentSubmit} >Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <MaterialTable
                                        title="Payment Details"
                                        columns={[
                                            { title: 'Transaction amount', field: 'transaction_amount' },
                                            { title: 'Remaning Amount', field: 'remaning_amount' },
                                            { title: 'Purpose', field: 'purpose' },
                                            { title: 'Remark', field: 'remark' },
                                            { title: 'Date & Time', field: 'remark', render: rowData => <>{moment(rowData.created_at).format('MM/DD/YYYY, h:mm a')}</> },

                                        ]}
                                        data={payment_detail}

                                        options={{
                                            actionsColumnIndex: -1
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
