import React from 'react'

export default function RightSideBar() {
    return (
        <div>
            <div className="right-bar">
                <div className="rightbar-title">
                    <a href="javascript:void(0);" className="right-bar-toggle float-right">
                        <i data-feather="x-circle"></i>
                    </a>
                    <h5 className="m-0">Customization</h5>
                </div>

                <div className="slimscroll-menu">
                    <h5 className="font-size-16 pl-3 mt-4">Choose Variation</h5>
                    <div className="p-3">
                        <h6>Default</h6>
                        <a href="index.html"><img src="assets/images/layouts/vertical.jpg" alt="vertical" className="img-thumbnail demo-img" /></a>
                    </div>
                    <div className="px-3 py-1">
                        <h6>Top Nav</h6>
                        <a href="layouts-horizontal.html"><img src="assets/images/layouts/horizontal.jpg" alt="horizontal" className="img-thumbnail demo-img" /></a>
                    </div>
                    <div className="px-3 py-1">
                        <h6>Dark Side Nav</h6>
                        <a href="layouts-dark-sidebar.html"><img src="assets/images/layouts/vertical-dark-sidebar.jpg" alt="dark sidenav" className="img-thumbnail demo-img" /></a>
                    </div>
                    <div className="px-3 py-1">
                        <h6>Condensed Side Nav</h6>
                        <a href="layouts-dark-sidebar.html"><img src="assets/images/layouts/vertical-condensed.jpg" alt="condensed" className="img-thumbnail demo-img" /></a>
                    </div>
                    <div className="px-3 py-1">
                        <h6>Fixed Width (Boxed)</h6>
                        <a href="layouts-boxed.html"><img src="assets/images/layouts/boxed.jpg" alt="boxed" className="img-thumbnail demo-img" /></a>
                    </div>
                </div>
            </div>

        </div>
    )
}
