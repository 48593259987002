import RightSideBar from '../components/layout_component/RightSideBar'
import {connect} from 'react-redux'
import {addToCart} from '../services/Actions/actions'

const mapStateToProps=state=>({
    
})
const mapDispatchToProps=dispatch=>({
    

})
export default connect(mapStateToProps,mapDispatchToProps)(RightSideBar)