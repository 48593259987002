import React, { Component } from 'react'
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { post, authformpost, authget } from '../../utils/service'
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { imageValid } from '../../utils/imageValid';
import { Link, withRouter } from 'react-router-dom'
import * as CONSTANT from '../../utils/constant';
import {updateProfile} from './../../services/Actions/actions'
import {connect} from 'react-redux'

 class ViewProfile extends Component {
    constructor(props) {
        super(props)
        window.scrollTo(0, 0);
        this.state = {
            loading: false,
            name: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            extra_phone_no: '',
            pin_code: '',
            profileImg:''
        }
        this.validator = new SimpleReactValidator({
            messages: {
                in: 'Passwords need to match!'
            }
        })
        this.validator1 = new SimpleReactValidator({
            messages: {
                in: 'Passwords need to match!'
            }
        })

    }

    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }

    imageHandler = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                this.setState({ profileImg: reader.result })
            }
        }
        reader.readAsDataURL(e.target.files[0])
    };

    validateImage = (e) => {
        var formData = new FormData();

        var id = e.target.id
        var file = document.getElementById(id).files[0];
        // console.log(id);
        formData.append("Filedata", file);
        var t = file.type.split("/").pop().toLowerCase();
        if (t != "jpeg" && t != "jpg" && t != "png" && t != "bmp" && t != "gif") {
            //   alert('Please select a valid image file');
            const id = toast.error('Please select a valid image file!');

            e.target.value = "";
            return false;
        }
        if (file.size > 2048000) {
            // alert('Max Upload size is 1MB only');
            const id = toast.error('Max Upload size is 2MB only!');

            e.target.value = "";
            return false;
        }
        this.imageHandler(e);
        return true;
    }
    handleSubmit11 = (e) => {
        e.preventDefault();
        this.setState({loading:true})
        var formdata = new FormData(e.target);
        var formdata1 = [...formdata];
        authformpost('/api/profileUpdate', formdata)
            .then((data) => {
                if(data.data.success === true){
                 this.setState({
                    profileImg: `${CONSTANT.URL}/storage/${data.data.user_details.profile_image}`,
                 })
                 this.props.updateProfile()
                 toast.dark(data.data.msg,
                 { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
            
                }else{
                    toast.dark(data.data.msg,
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                }
                  this.setState({loading:false})

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    componentDidMount() {
        this.props.updateProfile()
        authget(`/api/ShowUserDetails`)
            .then((response) => {
                if (response.data.status === true) {
                    this.setState({
                        name: response.data.user.name,
                        address: response.data.user.address,
                        email: response.data.user.email,
                        phone: response.data.user.phone,
                        city: response.data.city_details.city_name,
                        state: response.data.user_details.state,
                        profileImg: `${CONSTANT.URL}/storage/${response.data.user_details.profile_image}`,
                        pin_code: response.data.user_details.pin_code,
                        extra_phone_no: response.data.user_details.extra_phone_no,
                    })

                } else {
                    this.setState({
                        name: response.data.user.name,
                        address: response.data.user.address,
                        email: response.data.user.email,
                        phone: response.data.user.phone,
                    })
                }


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        var formdata = new FormData(e.target);
        if (this.validator.allValid()) {
            authformpost('/api/userDetailsUpdate', formdata)
                .then((data) => {
                    if (data.data.success === true) {
                        this.setState({
                            name: data.data.user.name,
                            address: data.data.user.address,
                            email: data.data.user.email,
                            phone: data.data.user.phone,
                            city: data.data.user_details.city,
                            state: data.data.user_details.state,
                            profileImg: `${CONSTANT.URL}/storage/${data.data.user_details.profile_image}`,
                            pin_code: data.data.user_details.pin_code,
                            extra_phone_no: data.data.user_details.extra_phone_no,
                        })
                        this.props.updateProfile()
                        toast.dark(data.data.msg,
                            { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                        this.setState({ loading: false })

                    } else {
                        toast.dark(data.data.msg,
                            { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                        this.setState({ loading: false })


                    }
                    // console.log(data.data)
                    // toast.dark('Register Successful',
                    //     { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                    // this.props.history.push('/login');
                    // this.setState({ loading: false });

                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    }

    handleSubmit1=(e)=>{
        e.preventDefault();
        this.setState({ loading: true });
        var formdata = new FormData(e.target);
        if (this.validator1.allValid()) {
            authformpost('/api/change_password',formdata)
                .then((data) => {
                    // console.log(data.data.error.current_password[0])
                    if(data.data.status === true){
                        toast.dark('Password Change Successfully ',
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                   
                    }else{
                        toast.dark(data.data.error.current_password[0],
                            { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                    }
                  this.setState({loading:false})
                  this.props.updateProfile()
                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            this.validator1.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    }

    render() {
        return (
            <div>
                <div className="content">
                    <div className="container-fluid">
                        <div className="row page-title">
                            <div className="col-md-12">
                                <nav aria-label="breadcrumb" className="float-right mt-1">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Profile</li>
                                    </ol>
                                </nav>
                                <h4 className="mb-1 mt-0">Profile</h4>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-3">
                                <div className="card" style={{ "height": "100%" }}>
                                    <div className="card-body">
                                        <div className="text-center mt-3">
                                            <form onSubmit={this.handleSubmit11}>
                                                {/* <img src="assets/images/users/avatar-7.jpg" alt="" className="avatar-lg rounded-circle" /> */}
                                               
                                                {this.state.profileImg === '' ?
                                                <img src='https://cdn.iconscout.com/icon/free/png-256/user-1648810-1401302.png' style={{ width: "50%", "border-radius": "100%", "margin-top": "10px", "margin-bottom": "10px" }} className="avatar-img rounded-circle" />
                                                :
                                                <img src={this.state.profileImg} style={{ width: "50%", "border-radius": "100%", "margin-top": "10px", "margin-bottom": "10px" }} className="avatar-img rounded-circle" />
                                            }
                                                <h5 className="mt-2 mb-0">{this.state.name}</h5>

                                                <input type="file" className="form-control" name="profile_image" id="profile_image" required onChange={this.validateImage}/> <br />
                                                <button type="submit" className="btn btn-primary btn-sm mr-1"disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Update Here'}</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-9">
                                <div className="card">
                                    <div className="card-body">
                                        <ul className="nav nav-pills navtab-bg nav-justified" id="pills-tab" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="pills-activity-tab" data-toggle="pill" href="#pills-activity" role="tab" aria-controls="pills-activity" aria-selected="true">
                                                    Profile Details
                                                </a>
                                            </li>

                                            <li className="nav-item">
                                                <a className="nav-link" id="pills-messages-tab" data-toggle="pill" href="#pills-messages" role="tab" aria-controls="pills-messages" aria-selected="false">
                                                    Change Password
                                                </a>
                                            </li>


                                        </ul>

                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-activity" role="tabpanel" aria-labelledby="pills-activity-tab">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="card">
                                                            <div className="card-body">

                                                                <form className="needs-validation" onSubmit={this.handleSubmit} >
                                                                    <div className="form-group mb-3">
                                                                        <label for="validationCustom01">Name </label>
                                                                        <input type="text" className="form-control" id="name" name="name" placeholder="Enter  Name" onChange={this.handleChange} value={this.state.name} />
                                                                        {this.validator.message('name', this.state.name, 'required', { className: 'text-danger' })}
                                                                    </div>

                                                                    <div className="form-group mb-3">
                                                                        <label for="validationCustom02">Email Id (Did Not Change)</label>
                                                                        <input type="text" className="form-control" id="" name="" placeholder="Enter Email Id" value={this.state.email} />

                                                                    </div>
                                                                    <div className="form-group mb-3">
                                                                        <label for="validationCustom02"> Phone Number (Did Not Change)</label>
                                                                        <input type="number" className="form-control" id="" name="" placeholder="Enter Phone Number" value={this.state.phone} />
                                                                    </div>
                                                                    <div className="form-group mb-3">
                                                                        <label for="validationCustom02"> Address</label>
                                                                        <input type="text" className="form-control" id="address" name="address" placeholder="address" onChange={this.handleChange} value={this.state.address} />
                                                                        {this.validator.message('address', this.state.address, 'required', { className: 'text-danger' })}

                                                                    </div>
                                                                    <div className="form-group mb-3">
                                                                        <label for="validationCustom02"> City (Did Not Change)</label>
                                                                        <input type="text" className="form-control" id="" name="" placeholder="City" value={this.state.city} />

                                                                    </div>


                                                                    <div className="form-group mb-3">
                                                                        <label for="validationCustom02"> Extra Phone Number</label>
                                                                        <input type="number" className="form-control" id="extra_phone_no" name="extra_phone_no" placeholder="Extra Phone Number" onChange={this.handleChange} value={this.state.extra_phone_no} />
                                                                        {this.validator.message('extra_phone_no', this.state.extra_phone_no, 'required', { className: 'text-danger' })}
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group mb-3">
                                                                                <label for="validationCustom02"> State</label>
                                                                                <input type="text" className="form-control" id="state" name="state" placeholder="Enter State" onChange={this.handleChange} value={this.state.state} />
                                                                                {this.validator.message('state', this.state.state, 'required', { className: 'text-danger' })}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group mb-3">
                                                                                <label for="validationCustom02"> Pin code </label>
                                                                                <input type="number" className="form-control" id="pin_code" name="pin_code" placeholder="Enter Pin Code" onChange={this.handleChange} value={this.state.pin_code} />
                                                                                {this.validator.message('pin_code', this.state.pin_code, 'required', { className: 'text-danger' })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <button className="btn btn-primary" type="submit" disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Update Here'}</button>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                            <div className="tab-pane" id="pills-messages" role="tabpanel" aria-labelledby="pills-messages-tab">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="card">
                                                            <div className="card-body">

                                                                <form className="needs-validation" onSubmit={this.handleSubmit1} >
                                                                    <div className="form-group mb-3">
                                                                        <label for="validationCustom01">Enter Current Password </label>
                                                                        <input type="password" className="form-control" id="current_password" name="current_password" placeholder="Enter  current password" onChange={this.handleChange} value={this.state.current_password} />
                                                                        {this.validator1.message('current_password', this.state.current_password, 'required', { className: 'text-danger' })}

                                                                    </div>

                                                                    <div className="form-group mb-3">
                                                                        <label for="validationCustom02">New Password</label>
                                                                        <input type="password" className="form-control" id="password" name="password" placeholder="Enter New password" onChange={this.handleChange} value={this.state.password} />
                                                                        {this.validator1.message('password', this.state.password, 'required|min:8', { className: 'text-danger' })}

                                                                    </div>
                                                                    <div className="form-group mb-3">
                                                                        <label for="validationCustom02"> Confirm Password</label>
                                                                        <input type="password" className="form-control" id="confirm_password" name="confirm_password" placeholder="Enter confirm password"  onChange={this.handleChange} value={this.state.confirm_password} />
                                                                        {this.validator1.message('confirm_password', this.state.confirm_password, `required|in:${this.state.password}`, { className: 'text-danger' }, { messages: { in: 'Passwords need to match!' } })}

                                                                    </div>






                                                                    <button className="btn btn-primary" type="submit" disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Change Password'}</button>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    console.log(state)
    // return {
    //   data: state.loginModal
    // }
  }
const mapDispatchToProps=dispatch=>({
    updateProfile: () => { dispatch(updateProfile()) },
})
export default connect(mapStateToProps,mapDispatchToProps)(ViewProfile)

