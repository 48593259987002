import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { toast } from "react-toastify";
import { post, authformpost, authget, authpost } from "../../utils/service";
import "react-toastify/dist/ReactToastify.css";
import { Link, withRouter } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import * as CONSTANT from "../../utils/constant";
import queryString from "query-string";

toast.configure();

function ViewRating(props) {
  const parsed = queryString.parse(props.location.search);
  const { id } = parsed;
  const [rating, setRating] = useState([]);
  const [open, setopen] = useState(false);
  // const [PlumberDetails, setPlumberDetails] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    ah();
  }, []);

  const ah = () => {
    const data = {
      worker_id: id,
    };
    authpost(`/api/viewprating`, data)
      .then((response) => {
        console.log(response?.data?.data);
        setRating(response.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const statuseChange = (id) => {
    let data = { worker_id: id };
    authpost("/api/workerstatuschange", data)
      .then((response) => {
        ah();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const abc = (id) => {
    props.history.push("/view-plumber-rating?id=" + id);
  };

  return (
    <div>
      <div className="content">
        <div className="container-fluid">
          <div className="row page-title">
            <div className="col-md-12">
              <nav aria-label="breadcrumb" className="float-right mt-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Service Providers Rating
                  </li>
                </ol>
              </nav>
              <h4 className="mb-1 mt-0"> Service Providers Rating</h4>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4
                    className="header-title mt-0 mb-1"
                    style={{ float: "right" }}
                  >
                    {/* <Link to="add-plumber" className="btn btn-success btn-sm">
                      Add
                    </Link> */}
                  </h4>
                  <br></br>
                  <br></br>
                  <MaterialTable
                    title="Service Providers Rating"
                    columns={[
                      { title: "Rating", field: "rating" },
                      { title: "Details", field: "details" },
                      { title: "User Name", field: "name" },
                    ]}
                    data={rating}
                    options={{
                      actionsColumnIndex: -1,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(ViewRating);
