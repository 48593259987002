import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, withRouter } from "react-router-dom";
import { authget, authpost, get } from "../../utils/service";
import { checkAuth } from "../../utils/auth";
var urlCrypt = require("url-crypt")(
  '~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF'
);
toast.configure();
export default function Login(props) {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState("password");

  if (checkAuth.isAuthenticated) {
    props.history.push("/");
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      phone: phone,
      password: password,
      role: 3,
    };
    authpost("/api/consaltentLogin", data)
      .then((res) => {
        if (res.data.status == false) {
          console.log(res.data);
          toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
        } else {
          toast.dark("Login Successfully", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
          checkAuth.authenticate();
          localStorage.setItem("consaltentToken", res.data.response.token);
          localStorage.setItem("consaltentName", res.data.response.user.name);
          localStorage.setItem("consaltent_id", res.data.response.user.id);
          localStorage.setItem("consaltentEmail", res.data.response.user.email);
          localStorage.setItem("consaltentPhone", res.data.response.user.phone);
          props.history.push("/");
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const forgetpassword = (e) => {
    e.preventDefault();
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    if (re.test(phone)) {
      const formdata = {
        phone: phone,
      };
      authpost("/api/phone_no_check_in_forget_consaltent", formdata)
        .then((data) => {
          console.log(data.data);
          if (data.data.success == true) {
            var base64 = urlCrypt.cryptObj(phone);
            props.history.push(`/forgotpassword?phone=${base64}`);
          } else {
            toast.dark("Phone Number Already Exits", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {
          // this.setState({ loading: false });
        });
    } else {
      toast.dark("Please Enter Valid Phone Number", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    }
  };
  const showHide = () => {
    if (show == "password") {
      setShow("text");
    } else {
      setShow("password");
    }
  };
  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10" style={{ "margin-top": "75px" }}>
            <div className="card">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-md-6 p-5">
                    <div className="mx-auto mb-5">
                      <img src="assets/img/brand/logo.jpg" alt="" />
                    </div>
                    <h6 className="h5 mb-0 mt-4">Welcome back!</h6>
                    <p className="text-muted mt-1 mb-4">
                      Enter your Phone Number and password to access Consultant
                      panel.
                    </p>
                    <form
                      action="#"
                      onSubmit={handleSubmit}
                      className="authentication-form"
                    >
                      <div className="form-group">
                        <label className="form-control-label">
                          Phone Number
                        </label>
                        <div className="input-group input-group-merge">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="icon-dual" data-feather="mail"></i>
                            </span>
                          </div>
                          <input
                            type="number"
                            className="form-control"
                            id="phone"
                            name="phone"
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                            placeholder="Enter Phone Number"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group mt-4">
                        <label className="form-control-label">Password</label>
                        <a
                          href="#"
                          onClick={forgetpassword}
                          className="float-right text-muted text-unline-dashed ml-1"
                        >
                          Forgot your password?
                        </a>
                        <div className="input-group input-group-merge">
                          <input
                            type={`${show}`}
                            className="form-control"
                            id="password"
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            placeholder="Enter your password"
                            required
                          />
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              style={{ cursor: "pointer" }}
                              onClick={showHide}
                            >
                              {/* <i className="icon-dual" data-feather="lock"></i> */}
                              {show == "password" ? (
                                <i className="fas fa-eye-slash"></i>
                              ) : (
                                <i className="fas fa-eye"></i>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="checkbox-signin"
                            required
                          />
                          <label
                            className="custom-control-label"
                            for="checkbox-signin"
                          >
                            Remember me
                          </label>
                        </div>
                      </div>
                      <div className="form-group mb-0 text-center">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-6 d-none d-md-inline-block">
                    <div className="auth-page-sidebar">
                      <div className="overlay"></div>
                      <div className="auth-user-testimonial">
                        {/* <p className="font-size-24 font-weight-bold text-white mb-1">I simply love it!</p> */}
                        {/* <p className="lead">"It's a elegent templete. I love it very much!"</p> */}
                        <p>- Consultant User</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row mt-3">
                            <div className="col-12 text-center">
                                <p className="text-muted">Don't have an account? <Link to="register" className="text-primary font-weight-bold ml-1">Sign Up</Link></p>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
