import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, withRouter } from 'react-router-dom'
import { authget, authpost,get} from '../../utils/service'
import {checkAuth} from '../../utils/auth'
toast.configure();
export default class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      city:[],
      city_id:'',
      name: '',
      email: '',
      phone: '',
      role: 3,
      password: '',
    }
    this.validator = new SimpleReactValidator();
  }
  componentDidMount(){
    get(`/api/viewCity`)
    .then((response) => {
        this.setState({'city':response.data.city})
    })
    .catch(function (error) {
        console.log(error);
    });
  }
  UNSAFE_componentWillMount() {
    if (checkAuth.isAuthenticated)
        this.props.history.push('/')
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      'name': this.state.name,
      'email': this.state.email,
      'phone': this.state.phone,
      'city_id':this.state.city_id,
      'role_id': this.state.role,
      'password': this.state.password
    }
    if (this.validator.allValid()) {
      this.setState({
        loading: true,
      });
      authpost("/api/register1", data)
        .then((res) => {
          console.log(res.data)
          if (res.data.status == false) {
            this.setState({
              loading: false,
            });
              toast.error(res.data.response, {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 3000,
            });
          } else {
            this.setState({
              loading: false,
            });
            toast.dark("Consultant Create Successfully", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 3000,
            });
            checkAuth.authenticate();
            localStorage.setItem('consaltentToken', res.data.response.token);
            localStorage.setItem('consaltentName', res.data.response.user.name);
            localStorage.setItem('consaltent_id', res.data.response.user.id);
            localStorage.setItem('consaltentEmail', res.data.response.user.email);
            localStorage.setItem('consaltentPhone', res.data.response.user.phone);
            this.props.history.push('/');
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            loading: false,
          });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({
        loading: false,
      });
    }
  };
  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    return (
      <div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10" style={{ "margin-top": "75px" }}>
              <div className="card">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-md-6 p-5">
                      <div className="mx-auto mb-5">

                        <img src="assets/img/brand/logo.jpg" alt="" />

                      </div>

                      <h4 className="h5 mb-0 mt-4">Register</h4>


                      <form onSubmit={this.handleSubmit} className="authentication-form">
                        <div className="form-group">
                          <label className="form-control-label">
                            Name
                          </label>
                          <div className="input-group input-group-merge">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i
                                  className="icon-dual"
                                  data-feather="mail"
                                ></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Enter Your Name"
                              onChange={this.handleChange} 
                              value={this.state.name}
                            />
                          </div>
                          {this.validator.message('name', this.state.name, 'required',{ className: 'text-danger' })}
                        </div>

                        <div className="form-group">
                          <label className="form-control-label">
                            Phone Number
                          </label>
                          <div className="input-group input-group-merge">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i
                                  className="icon-dual"
                                  data-feather="mail"
                                ></i>
                              </span>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              id="phone"
                              name="phone"
                              placeholder="Enter Your Phone Number"
                              onChange={this.handleChange} 
                              value={this.state.phone}
                            />
                          </div>
                          {this.validator.message('phone', this.state.phone, 'required|max:10',{ className: 'text-danger' })}

                        </div>
                        <div className="form-group">
                          <label className="form-control-label">
                            Email Address
                          </label>
                          <div className="input-group input-group-merge">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i
                                  className="icon-dual"
                                  data-feather="mail"
                                ></i>
                              </span>
                            </div>
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              onChange={this.handleChange} 
                              value={this.state.email}
                              placeholder="hello@coderthemes.com"
                            />
                          </div>
                          {this.validator.message('email', this.state.email, 'required',{ className: 'text-danger' })}

                        </div>


                        <div className="form-group">
                          <label className="form-control-label">
                            City
                          </label>
                          <div className="input-group input-group-merge">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i
                                  className="icon-dual"
                                  data-feather="mail"
                                ></i>
                              </span>
                            </div>
                            <select name="city_id" id="city_id"
                              onChange={this.handleChange} 
                              value={this.state.city_id}
                              className="form-control">
                              <option value='' disabled selected>--Select Option--</option>
                              {this.state.city.map((object,i)=>
                                <option value={object.id} key={object.id}>{object.city_name}</option>
                              )}
                            </select>
                          </div>
                          {this.validator.message('city_id', this.state.city_id, 'required',{ className: 'text-danger' })}

                        </div>

                        <div className="form-group mt-4">
                          <label className="form-control-label">Password</label>
                          <div className="input-group input-group-merge">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i
                                  className="icon-dual"
                                  data-feather="lock"
                                ></i>
                              </span>
                            </div>
                            <input
                              type="password"
                              className="form-control"
                              id="password"
                              onChange={this.handleChange} 
                              value={this.state.password}
                              name="password"
                              placeholder="Enter your password"
                            />
                          </div>
                          {this.validator.message('password', this.state.password, 'required|min:8',{ className: 'text-danger' })}
                        </div>
                        <div className="form-group mb-0 text-center">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Register
                          </button>
                        </div>
                      </form>

                    </div>
                    <div className="col-lg-6 d-none d-md-inline-block">
                      <div className="auth-page-sidebar">
                        <div className="overlay"></div>
                        <div className="auth-user-testimonial">
                          <p className="font-size-24 font-weight-bold text-white mb-1">
                            I simply love it!
                          </p>
                          <p className="lead">
                            "It's a elegent templete. I love it very much!"
                          </p>
                          <p>- Consaltent User</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-12 text-center">
                  <p className="text-muted">
                    I already have an account?{" "}
                    <Link
                      to="login"
                      className="text-primary font-weight-bold ml-1"
                    >
                      Sign In
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
