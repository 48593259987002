import React from 'react'
import {Link} from 'react-router-dom'

export default function Default_page() {
    return (
        <div>
            <div className="account-pages my-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-5 col-8">
                            <div className="text-center">
                                <div>
                                    <img src="assets/images/not-found.png" alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 text-center">
                            <h3 className="mt-3">We couldn’t connect the dots</h3>
                                <p className="text-muted mb-5">
                                    This page was not found. <br />
                                    You may have mistyped the address or the page may have moved.
                                </p>
                            <Link to="/" className="btn btn-lg btn-primary mt-4">Take me back to Home</Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
