import ViewProfile from '../components/profile_component/ViewProfile'

import {connect} from 'react-redux'


const mapStateToProps=state=>({
    
})
const mapDispatchToProps=dispatch=>({
    

})
export default connect(mapStateToProps,mapDispatchToProps)(ViewProfile)