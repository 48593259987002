import MainHome from '../components/dashboard_component/MainHome'
import {connect} from 'react-redux'


const mapStateToProps=state=>({
 
})
const mapDispatchToProps=dispatch=>({
   
})
export default connect(mapStateToProps,mapDispatchToProps)(MainHome)