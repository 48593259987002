import React, { useState, useEffect } from 'react'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, withRouter } from 'react-router-dom'
import { authget, authpost, get } from '../../utils/service'
import { checkAuth } from '../../utils/auth'
const queryString = require('query-string');
toast.configure();
var urlCrypt = require('url-crypt')('~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF');

export default function ForgotPassword(props) {
    let param = props.location.search;
    const parsed = queryString.parse(param);
    console.log(parsed.phone)
    var backAgain = urlCrypt.decryptObj(parsed.phone);
    const [phone, setPhone] = useState(backAgain);
    const [password, setPassword] = useState('');
    const [show, setShow] = useState('password');


    if (checkAuth.isAuthenticated) {
        props.history.push('/')
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            'phone': phone,
            'password': password,
        }
        authpost("/api/forgot_password", data)
            .then((res) => {
                if (res.data.status == 1) {
                    toast.dark(res.data.msg, {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 3000,
                    });
                    props.history.push('/login');
                } else {
                    toast.error(res.data.msg, {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 3000,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                //   this.setState({
                //     loading: false,
                //   });
            });
    };
    const showHide =()=>{
        if(show=='password'){
            setShow('text')
        }else{
            setShow('password')
        }
    }
    
    return (
        <div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-10" style={{ "margin-top": "75px" }}>
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-md-6 p-5">
                                        <div className="mx-auto mb-5">
                                            <img src="assets/img/brand/logo.jpg" alt="" />
                                        </div>
                                        <h6 className="h5 mb-0 mt-4">Welcome back!</h6>
                                        <p className="text-muted mt-1 mb-4">Enter your New Password to Change Your Password.</p>
                                        <form action="#" onSubmit={handleSubmit} className="authentication-form">
                                            <div className="form-group">
                                                <label className="form-control-label">Phone Number</label>
                                                <div className="input-group input-group-merge">
                                                   
                                                    {phone}
                                                    {/* <input type="number" className="form-control" id="phone" name="phone" onChange={(e) => setPhone(e.target.value)} value={phone} placeholder="Enter Phone Number" required /> */}
                                                </div>
                                            </div>
                                            <div className="form-group mt-4">
                                                <label className="form-control-label">Password</label>
                                              
                                                <div className="input-group input-group-merge">
                                                   
                                                    <input type={`${show}`} className="form-control" id="password" name="password" onChange={(e) => setPassword(e.target.value)} value={password} placeholder="Enter your password" required />
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" style={{cursor: "pointer"}} onClick={showHide}>
                                                            {/* <i className="icon-dual" data-feather="lock"></i> */}
                                                           {show == 'password'?
                                                           <i className="fas fa-eye-slash"></i>

                                                           :
                                                           <i className="fas fa-eye"></i>

                                                           }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                            <div className="form-group mb-0 text-center">
                                                <button className="btn btn-primary btn-block" type="submit">Forgot Password</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-lg-6 d-none d-md-inline-block">
                                        <div className="auth-page-sidebar">
                                            <div className="overlay"></div>
                                            <div className="auth-user-testimonial">
                                                <p className="font-size-24 font-weight-bold text-white mb-1">I simply love it!</p>
                                                <p className="lead">"It's a elegent templete. I love it very much!"</p>
                                                <p>- Consultant User</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 text-center">
                                <p className="text-muted"><Link to="login" className="text-primary font-weight-bold ml-1">Sign In</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
