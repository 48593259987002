import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'
import { toast } from 'react-toastify';
import { post, authformpost, authget } from '../../utils/service'
import 'react-toastify/dist/ReactToastify.css';
import { Link, withRouter } from 'react-router-dom'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import * as CONSTANT from '../../utils/constant';
toast.configure();
function View_inquiry(props) {
    const [enquery, setEnquery] = useState([])
    const [open, setopen] = useState(false)
    const [data, setdata] = useState([])
    useEffect(() => {
        window.scrollTo(0, 0);
        authget(`/api/showEnquery_consaltent`)
            .then((response) => {
                // console.log(response.data)
                setEnquery(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])
    const onCloseModal = () => {
        setopen(false)
    }
    const showDetails = (id) => {
        props.history.push('/View_order_details?id=' + id)
    }
    const viewDetails = (rowData) => {
        setopen(true)
        setdata(rowData)
        // console.log(rowData)
    }
    return (
        <div>
            <Modal open={open} onClose={onCloseModal} >
                <h2>Booking Details </h2>
                <div className="row">
                    <div className="col-12" style={{ width: "750px" }}>
                        <div className="card shadow">
                            <div className="card-header bg-transparent border-0">
                                <table className="table">

                                    <tbody>

                                        <tr>
                                            <th scope="row">Message</th>
                                            <td>{data.client_msg}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Service Price</th>
                                            <td>{data.service_price}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Coupon Price</th>
                                            <td>{data.service_price - data.with_coupon_price}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Paybale Price</th>
                                            <td>{data.with_coupon_price}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>

            <div className="row page-title">
                <div className="col-md-12">
                    <nav aria-label="breadcrumb" className="float-right mt-1">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Booking</li>
                        </ol>
                    </nav>
                    <h4 className="mb-1 mt-0">Booking</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="">
                        <div className="">
                            <MaterialTable
                                title="Booking list"
                                columns={[
                                    { title: 'Order Id', field: 'order_id' },
                                    { title: 'Service  Name', field: 'service_name', render: rowData => <>{rowData.get_service.service_name}</> },
                                    { title: 'City Name', field: 'city_id', render: rowData => <>{rowData.get_city.city_name}</> },
                                    { title: 'Client Name', field: 'client_name' },
                                    { title: 'Contact No', field: 'client_phone' },
                                    { title: 'E-mail', field: 'client_email' },

                                ]}
                                data={enquery}
                                actions={[
                                    {
                                        icon: 'visibility',
                                        tooltip: 'Booking Details',
                                        onClick: (event, rowData) => viewDetails(rowData)

                                    },
                                    {
                                        icon: 'visibility',
                                        tooltip: 'Traking',
                                        onClick: (event, rowData) => showDetails(rowData.enquery_id)

                                    },

                                ]}
                                options={{
                                    actionsColumnIndex: -1
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default withRouter(View_inquiry)