import { React, useState, useEffect } from 'react';
import { get,authget } from '../../utils/service'
import { Link } from 'react-router-dom'
import { Line } from 'react-chartjs-2';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
export default function MainHome() {
    const [booking, setbooking] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [customizebooking, setCustomizebooking] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [bookingcount, setBookingcount]=useState(0);
    const [bookingcountc, setBookingcountc]=useState(0);
    const [user, setUser]=useState(0);
    useEffect(() => {
        window.scrollTo(0, 0);
        authget(`/api/ConsaltentDasbord`)
            .then((response) => {
                console.log(response.data);
                setBookingcount(response.data.booking)
                setBookingcountc(response.data.customizebooking)
                setUser(response.data.user)
                setbooking(response.data.booking_year_array)
                setCustomizebooking(response.data.custom_booking_year_array)

            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])
    
    const data = {
        labels: ['january', 'february', 'March', 'April', 'May', 'Jun', 'July', 'August', 'september', 'october', 'november', 'december'],
        datasets: [
            {
                label: 'booking',
                data: booking,
                // borderColor:['#DE4839','#DE4839','#DE4839','#DE4839','#DE4839','#DE4839','#DE4839','#DE4839','#DE4839','#DE4839','#DE4839','#DE4839','#DE4839'],
                // backgroundColor:['#5A20CB','#5A20CB','#5A20CB','#5A20CB','#5A20CB','#5A20CB','#5A20CB','#5A20CB','#5A20CB','#5A20CB','#5A20CB','#5A20CB'],
                borderColor: ['#DE4839'],
                backgroundColor: ['#5A20CB'],

                pointBorderColor: ['#3DBE29'],
                pointBackgroundColor: ['#EDC126']
            },
            {
                label: 'Custom Booking',
                data: customizebooking,
                borderColor: ['#F4BE2C'],
                backgroundColor: ['#35BDD0'],
                pointBorderColor: ['#EDC126'],
                pointBackgroundColor: ['#3DBE29']
            }
        ]
    }
    const value = 30;
    return (
        <div>
            <div className="content">
                <div className="container-fluid">
                    <div className="row page-title align-items-center">
                        <div className="col-sm-4 col-xl-6">
                            <h4 className="mb-1 mt-0">Dashboard</h4>
                        </div>
                        <div className="col-sm-8 col-xl-6">
                            <nav aria-label="breadcrumb" className="float-right mt-1">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-xl-4">
                            <div className="card text-center">
                                <div className="card-body p-3">
                                    <CircularProgressbar value={value} maxValue={1} text={`${user}`} />
                                    <h3 className="">Total User</h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-4">
                            <div className="card text-center">
                                <div className="card-body p-0">
                                    <div className="card-body p-3">

                                        <CircularProgressbar value={value} maxValue={1} text={`${bookingcount}`} />
                                        <h3 className="">Total Booking</h3>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-4">
                            <div className="card text-center">
                                <div className="card-body p-0">
                                    <div className="card-body p-3">

                                        <CircularProgressbar value={value} maxValue={1} text={`${bookingcountc}`} />
                                        <h3 className="">Total Custom Booking</h3>

                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>


                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body p-3">
                                    <h5 className="card-title header-title border-bottom p-3 mb-0">Overview</h5>
                                    <Line data={data} />

                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>


        </div>

    )
}
