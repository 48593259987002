import React, { Component } from "react";
import {
  withRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import "./App.css";
import { checkAuth } from "./utils/auth";
import Login from "./components/Auth_component/Login";
import Register from "./components/Auth_component/Register";
import AddProfileContainer from "./containers/AddProfileContainer";
import FooterContainer from "./containers/FooterContainer";
import HeaderContainer from "./containers/HeaderContainer";
import MainContainer from "./containers/MainContainer";
import RightBarContainer from "./containers/RightBarContainer";
import SidebarContainer from "./containers/SidebarContainer";
import ViewProfileContainer from "./containers/ViewProfileContainer";
import ViewUserContainer from "./containers/ViewUserContainer";
import Customizeoder from "./components/order/Customizeoder";
import Order from "./components/order/Order";
import Add_user from "./components/user/Add_user";
import { updateProfile } from "./services/Actions/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import View_order_details from "./components/order/View_order_details";
import Default_page from "./components/404_page/Default_page";
import ForgotPassword from "./components/Auth_component/ForgotPassword";
import Offer from "./components/offer/Offer";
import Payment_transaction from "./components/Payment/Payment_transaction";
import AddPlumber from "./components/Plumber/AddPlumber";
import ViewPlumber from "./components/Plumber/ViewPlumber";
import ViewRating from "./components/Plumber/ViewRating";
import EditPlumber from "./components/Plumber/EditPlumber";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    if (
      this.props.history.location.pathname == "/login" ||
      this.props.history.location.pathname == "/register" ||
      this.props.history.location.pathname == "/forgotpassword"
    ) {
      var element1 = document.getElementById("a");
      element1.classList.remove("content-page");
    }
    if (localStorage.getItem("consaltentToken") !== null) {
      this.props.updateProfile();
    }
  }
  render() {
    return (
      <div>
        <div id="wrapper">
          <HeaderContainer />
          <SidebarContainer />
          <div id="a" className="content-page">
            <Switch>
              <PrivateRoute
                exact
                path="/"
                component={MainContainer}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/addprofile"
                component={AddProfileContainer}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/viewprofile"
                component={ViewProfileContainer}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/view_user"
                component={ViewUserContainer}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/add_user"
                component={Add_user}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/pre_booking"
                component={Order}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/customize_booking"
                component={Customizeoder}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/View_order_details"
                component={View_order_details}
              ></PrivateRoute>
              <Route exact path="/login" component={Login}></Route>
              <Route
                exact
                path="/forgotpassword"
                component={ForgotPassword}
              ></Route>
              <Route exact path="/register" component={Register}></Route>
              <PrivateRoute
                exact
                path="/offer"
                component={Offer}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/add-plumber"
                component={AddPlumber}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/edit-plumber"
                component={EditPlumber}
              ></PrivateRoute>

              <PrivateRoute
                exact
                path="/view-plumber"
                component={ViewPlumber}
              ></PrivateRoute>

              <PrivateRoute
                exact
                path="/view-plumber-rating"
                component={ViewRating}
              ></PrivateRoute>

              <PrivateRoute
                exact
                path="/view-plumber-rating"
                component={ViewRating}
              ></PrivateRoute>

              <Route
                exact
                path="/payment_transaction"
                component={Payment_transaction}
              ></Route>
              <PrivateRoute component={Default_page}></PrivateRoute>
            </Switch>
          </div>
          <FooterContainer />
        </div>
        <RightBarContainer />
        <div className="rightbar-overlay"></div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data: state,
  };
};
const mapDispatchToProps = (dispatch) => ({
  updateProfile: () => {
    dispatch(updateProfile());
  },
});
// export default connect(mapStateToProps,mapDispatchToProps)(App)
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth.isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);
