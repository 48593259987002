import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, withRouter } from 'react-router-dom'
import { authget } from '../../utils/service'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import * as CONSTANT from '../../utils/constant';
function Customizeoder(props) {
    const [enquery, setEnquery] = useState([])
    const [open, setopen] = useState(false)
    const [data, setdata] = useState([])
    useEffect(() => {
        window.scrollTo(0, 0);
        authget(`/api/viewCustomize_consaltent`)
            .then((response) => {
                setEnquery(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    const onCloseModal = () => {
        setopen(false)
    }

    const showDetails = (id) => {
        props.history.push('/View_order_details?id=' + id)
    }
    const viewDetails = (rowData) => {
        setopen(true)
        setdata(rowData)
        console.log(rowData)
    }

    return (
        <div>
            <div className="row page-title">
                <div className="col-md-12">
                    <nav aria-label="breadcrumb" className="float-right mt-1">
                        <ol className="breadcrumb">

                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Customize Booking</li>
                        </ol>
                    </nav>
                    <h4 className="mb-1 mt-0">Customize Booking</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="">

                        <div className="">
                            <MaterialTable
                                title="Customize Booking list"
                                columns={[
                                    { title: 'Order Id', field: 'order_id' },
                                    { title: 'City Name', field: 'city_id', render: rowData => <>{rowData.get_city_customize.city_name}</> },
                                    { title: 'Client Name', field: 'name' },
                                    { title: 'Contact No', field: 'phone' },
                                    { title: 'E-mail', field: 'email' },
                                    { title: 'Message', field: 'message' },
                                    { title: 'DOC', field: 'doc', render: rowData => rowData.doc !== null ? <a href={`${CONSTANT.URL}/storage/${rowData.doc}`} target="_blank"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png" style={{ width: 50, }} /></a> : null },
                                ]}
                                data={enquery}
                                actions={[
                                   

                                    {
                                        icon: 'visibility',
                                        tooltip: 'Traking',
                                        onClick: (event, rowData) => showDetails(rowData.enquery_id)

                                    },

                                ]}
                                options={{
                                    actionsColumnIndex: -1
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default withRouter(Customizeoder)
